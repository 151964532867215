.header {
	background: url("../Images/Atlas\ Body.png");
	height: 100vh;
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
}
.header-part {
	position: absolute;
	top: 50%;
}
.header-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 50px;
	line-height: 53px;
	/* or 107% */

	display: flex;
	align-items: center;
	letter-spacing: 0.045em;
	width: 750px;
	color: #ffffff;
}
.header-deatils {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 53px;

	align-items: center;
	letter-spacing: 0.045em;

	color: #ffffff;
}
.btn-getstarted {
	border-radius: 10px;
	background: #a9e5ff;
	color: #082674;
	font-weight: 500;
	padding: 0.6rem 1.8rem;
	border: none;
	box-sizing: border-box;
}
.btn-getstarted:hover {
	background-color: #082674;
	color: white;
}
.box-part {
	background-color: #a9e5ff;
	border-radius: 20px;
}
.icons {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background-color: white;
	position: relative;
}
.fa-user,
.fa-map-marker-alt,
.fa-server {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
}
.top {
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 30px;
	/* or 120% */

	color: #082674;
}
.bottom-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */

	color: #4f5665;
}
@media (max-width: 900px) {
	.header {
		background-size: cover;
		background-position: center;
	}
	.header-text {
		width: 90%;
	}
}
@media (max-width: 600px) {
	.header-part {
		top: 30%;
		text-align: center;
	}
}
@media (min-width: 2000px) {
	.header {
		background-position: right;
	}
	.header-text {
		font-size: 60px;
	}
	.top {
		font-size: 35px;
	}
}
