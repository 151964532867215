.carosoul {
	margin-top: 6rem;
	margin-bottom: 6rem;
}
[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
	opacity: 1;
	transform: translateZ(0) scale(0.92);
}
.slider-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}
.img-slide {
	width: 46px;
	height: 46px;
	border-radius: 50%;
}
.grid-box-slider {
	border: 2px solid white;
	border-radius: 14px;
	height: 18rem;
}

.slider-title {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 35px;
	line-height: 50px;
	/* or 143% */

	text-align: center;

	color: #ffffff;
}
.slider-details {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	/* identical to box height, or 187% */

	text-align: center;

	color: #ffffff;
}

.user-name {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	/* or 167% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;
}
.user-profession {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 30px;
	/* or 214% */

	display: flex;
	align-items: center;
	text-align: center;

	color: #ffffff;
}
.rating {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	/* or 187% */

	display: flex;
	align-items: center;

	color: #ffffff;
}
.bottom-deatails {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	/* or 187% */

	display: flex;
	align-items: center;

	color: #ffffff;
}

.box {
	width: 50px;
	height: 15px;
	border-radius: 20px;
	background-color: #a9e5ff;
}
.round {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #ffffff;
}
.right-img {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #a9e5ff;
	border: 2px solid #a9e5ff;
	position: relative;
	box-sizing: border-box;
	box-shadow: 0px 14px 44px rgba(14, 20, 34, 0.08);
}
.fa-arrow-right {
	color: white;
	font-size: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.left-img,
.right-img {
	cursor: pointer;
}
@media (max-width: 1250px) {
	.grid-box-slider {
		height: auto;
	}
}
@media (max-width: 990px) {
	.slider-grid {
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 600px) {
	.slider-grid {
		grid-template-columns: 1fr;
	}
}
@media (max-width: 340px) {
	.logo {
		width: 150px;
	}
}
