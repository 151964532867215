.pricing {
	background: linear-gradient(
		180deg,
		#01013f -45.04%,
		rgba(248, 248, 248, 0) 88.56%
	);
}
.choose-path {
	font-family: Rubik;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 50px;
	/* identical to box height, or 125% */

	text-align: center;

	color: #ffffff;
}
.pricing-deatils {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 30px;
	/* or 187% */

	text-align: center;

	color: #ffffff;
}
.grid-logo {
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-gap: 20px;
	margin-bottom: 6rem;
	margin-top: 6rem;
}

.card-box {
	background-color: white;
	border-radius: 20px;
	border: 4px solid #01013f;
}
.grid-card {
	display: grid;
	margin-top: 3rem;
	margin-bottom: 6rem;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}
.card-img-title {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	/* identical to box height, or 167% */

	text-align: center;

	color: #0b132a;
}
.select {
	border-radius: 25px;
	background: #a9e5ff;
	color: #082674;
	font-weight: 500;
	padding: 0.5rem 1.5rem;
	border: 4px solid #082674;
	box-sizing: border-box;
	width: 177.88px;
}
.select:hover {
	background-color: #082674;
	color: white;
}
.right-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 30px;
	/* identical to box height, or 214% */

	color: #4f5665;
}
@media (max-width: 990px) {
	.grid-card {
		grid-template-columns: 1fr 1fr;
		margin: 0 auto;
	}
	.card-box {
		margin: 0 auto;
	}
}
@media (max-width: 700px) {
	.grid-card {
		grid-template-columns: 1fr;
		margin: 0 auto;
	}
}
