.title-feature {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 50px;
	line-height: 50px;
	/* identical to box height, or 100% */

	color: #ffffff;
}
.deatails-feature {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */

	color: #ffffff;
}
.feature-name {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 30px;
	/* identical to box height, or 150% */

	color: #ffffff;
}
.feature-part {
	margin-top: 10rem;
}
