li {
	list-style: none;
}
.footer {
	background-color: #a9e5ff;
	padding: 70px 0;
	margin-top: 45px;
}
.footer-width {
	margin-top: 6rem;
}
.logo-title {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height */

	color: #0b132a;
}
.deatils-part-footer {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 30px;
	/* or 187% */

	color: #4f5665;
}
.copyright {
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;

	color: #082674;
}
.engage {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	/* identical to box height, or 167% */

	color: #0b132a;
}
li a {
	text-decoration: none;
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	/* identical to box height, or 187% */

	color: #4f5665;
}
input {
	outline: none;
	width: 31rem;
	padding: 10px;
	background: #ffffff;
	border: 1px solid #082674;
	border-radius: 10px;
}
.subscribe-text {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 35px;
	line-height: 45px;
	/* or 129% */

	display: flex;
	align-items: center;

	color: #082674;
}
.social-icon {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	font-size: 24px;

	color: #082674;
	background-color: white;
}
.social-icon:hover {
	background-color: #082674;
	color: white;
}
.fa-facebook-f,
.fa-twitter,
.fa-instagram {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.subscribe {
	background-color: white;
	border-radius: 10px;
	width: 100%;
}
.bootom-part {
	position: relative;
	margin-top: 6rem;
}
.bottom {
	position: absolute;
	top: -20%;
}
.btn-subscribe {
	padding: 12px 30px;
	background: #082674;
	border-radius: 10px;
}
.btn-subscribe:focus {
	box-shadow: none;
}
@media (max-width: 990px) {
	.bootom-part {
		margin-top: 10rem;
	}
	.footer-width {
		margin-top: 10rem;
	}
}
@media (max-width: 700px) {
	input {
		width: 100%;
	}
}
@media (max-width: 400px) {
	.footer-width {
		margin-top: 14rem;
	}
}
